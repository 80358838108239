import { LangCodeEnum } from '../../../../config/intl/components/language-wrapper/LanguageWrapper'

export const getSiteVersion = () => {
  const hostname = window.location.hostname
  const domainListRu = process.env?.REACT_APP_DOMAIN_LIST_RU;
  const isRuDomain = domainListRu && domainListRu.split(',').includes(hostname);
  
  if (isRuDomain || hostname.endsWith('.ru') || hostname.includes('-ru')) {
    return {
      siteVersion: 'ru',
      langCode: LangCodeEnum.RU,
    }
  }
  
  return {
    siteVersion: 'en',
    langCode: LangCodeEnum.EN,
  }
}
